/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import * as icons from '@iconscout/react-unicons';

import Link from 'Component/Link/Link.component';
import MenuItem from 'Component/MenuItem';
import {
    Menu as SourceMenu
} from 'SourceComponent/Menu/Menu.component';
import { getSortedItems } from 'Util/Menu';
import { transformIcon } from 'Util/Transform/TransformIcon';

import PromoBanner from '../PromoBanner/PromoBanner.component';

import './Menu.style';

/** @namespace LjrPwa/Component/Menu/Component/MenuComponent */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable jsx-a11y/click-events-have-key-events */
export class MenuComponent extends SourceMenu {
    renderMobileMenuContact() {
        const { bottomBar, imageColumn } = this.props;
        if (!imageColumn || !bottomBar) {
            return null;
        }
        const itemArray = bottomBar.iconAndText;
        const imageArray = imageColumn.imageColumnLinks;
        const image = typeof imageArray === 'object' ? imageArray[imageArray.length - 1] : null;

        return (
            <div block="Menu" elem="MobileContact">
                <div className="image">
                    <Link to={ image.link } title={ image.image.fields.title }>
                        <img
                          height="100%"
                          width="auto"
                          src={ image.image.fields.file.url }
                          alt={ image.image.fields.title }
                        />
                    </Link>
                </div>
                { itemArray
                && itemArray.map((item, { item: iconName }) => {
                    const Icon = icons[transformIcon(item.iconName)];
                    return (
                        <p key={ iconName } className="phone">
                            <Icon className="spacer" />
                            { item.phoneNumberOrEmail }
                        </p>
                    );
                }) }
            </div>
        );
    }

    renderBottomBar() {
        const { bottomBar } = this.props;

        if (!bottomBar) {
            return null;
        }

        const itemArray = bottomBar.iconAndText;
        return (
            <div className="bottomBar">
                { itemArray
                && itemArray.map((item, { item: iconName }) => {
                    const Icon = icons[transformIcon(item.iconName)];
                    return (
                        <p key={ iconName } className="phone">
                            <Icon className="spacer" />
                            { item.phoneNumberOrEmail }
                        </p>
                    );
                }) }
                <div className="promoSection">
                    <img
                      className="imageCircle"
                      src={ bottomBar.image.fields.file.url }
                      alt={ bottomBar.image.title }
                    />
                    <p className="imageTitle">{ bottomBar.title }</p>
                    { this.renderColumnBottomLinks(bottomBar, '', 'promoLink') }
                </div>
            </div>
        );
    }

    renderImageColumn() {
        const { imageColumn, device, closeMenu } = this.props;

        if (!imageColumn) {
            return null;
        }

        if (device.isMobile) {
            return null;
        }

        const imageArray = imageColumn.imageColumnLinks;
        return (
            <div className="imageColumn">
                <div className="shoeGrid">
                    { imageArray
                    && imageArray.map((imageLink) => {
                        const { image, link } = imageLink;
                        return (
                            /* eslint-disable react/jsx-no-bind */
                            <Link key={ image.fields.title } to={ link } onClick={ () => closeMenu() }>
                                <img className="image" src={ image.fields.file.url } alt={ image.fields.title } />
                            </Link>
                        );
                    }) }
                </div>
                <div className="bottomSection">
                    <div>
                        <p className="topText">{ imageColumn.textTitle }</p>
                        <p className="topText">{ imageColumn.textSubtitle }</p>
                    </div>
                    <div>
                        { this.renderColumnBottomLinks(imageColumn, 'bottomText', '') }
                    </div>
                </div>
            </div>
        );
    }

    renderShopColumn() {
        const { shopColumn, closeMenu } = this.props;

        if (!shopColumn) {
            return null;
        }

        const itemArray = shopColumn.shopColumnItem;
        return (
            <div className="shopColumn">
                <div className="categoryTitle">
                    <h4>{ shopColumn.title }</h4>
                </div>
                <div className="listItems">
                    { itemArray
                    && itemArray.map((listItem) => (
                            <div className="container">
                                <img
                                  className="imageCircle"
                                  src={ listItem.circleImage.fields.file.url }
                                  alt="test"
                                />
                                <Link
                                  to={ listItem.textLink && listItem.textLink.identifier }
                                  className="itemText"
                                  onClick={ () => closeMenu() }
                                >
                                    { listItem.text }
                                </Link>
                            </div>
                    )) }
                </div>
                { this.renderColumnBottomLinks(shopColumn, 'bottomText', '') }
            </div>
        );
    }

    renderColumnBottomLinks(column, wrapperClass, linkClass) {
        // <Link className="promoLink" to="/">{ bottomBar.links }</Link>
        if (!column.links || column.links.length === 0) {
            return null;
        }

        const divider = (
            <>
                { '\u00A0' }
                |
                { '\u00A0' }
            </>
            );

        return (
            <div className={ wrapperClass }>
                { column.links.map((link) => {
                    if (link.url.indexOf('mailto' === 0)) {
                        /* eslint-disable jsx-a11y/anchor-is-valid */
                        /* eslint-disable react/jsx-no-bind */
                        return (
                            <Link
                              style={ { margin: 0, paddingLeft: 5 } }
                              to="#"
                              onClick={ (e) => {
                                    window.location = link.url;
                                    e.preventDefault();
                                } }
                              target="_blank"
                            >
                                { link.displayText }
                            </Link>
                        );
                    }

                    return (
                        <Link to={ link.url } title={ link.title } className={ linkClass }>
                            { link.displayText }
                        </Link>
                    );
}).reduce((prev, curr) => [prev, divider, curr]) }
            </div>
        );
    }

    renderDesktopSubLevel(category) {
        const { device } = this.props;
        const { children, item_class, item_id } = category;
        const childrenArray = getSortedItems(Object.values(children));

        if (device.isMobile || !childrenArray.length) {
            return null;
        }

        const isBanner = item_class === 'Menu-ItemFigure_type_banner';
        const isLogo = item_class === 'Menu-ItemFigure_type_logo';
        const mods = {
            isBanner: !!isBanner,
            isLogo: !!isLogo
        };

        return (
            <div
              block="Menu"
              elem="SubLevelDesktop"
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="ItemList"
                  mods={ { ...mods } }
                >
                    { childrenArray.map((item) => this.renderDesktopSubLevelItems(item, mods)) }
                </div>
            </div>
        );
    }

    renderSubLevelItems = (item) => {
        const {
            handleSubcategoryClick,
            activeMenuItemsStack,
            onCategoryHover,
            closeMenu,
            device
        } = this.props;

        const {
            item_id,
            children,
            item_class
        } = item;

        const isBanner = item_class === 'Menu-ItemFigure_type_banner';
        const isHideOnDesktop = item_class === 'Menu-ItemFigure_type_hideOnDesktop';
        const mods = {
            isBanner: !!isBanner,
            isHideOnDesktop: !!isHideOnDesktop
        };

        const childrenArray = Object.values(children);
        const subcategoryMods = { type: 'subcategory' };

        if (childrenArray.length && device.isMobile) {
            return (
                <div
                  key={ item_id }
                    // TODO: split into smaller components
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleSubcategoryClick(e, item) }
                  tabIndex="0"
                  role="button"
                >
                    <MenuItem
                      activeMenuItemsStack={ activeMenuItemsStack }
                      item={ item }
                      itemMods={ subcategoryMods }
                      onCategoryHover={ onCategoryHover }
                      closeMenu={ closeMenu }
                    />
                    { this.renderSubLevel(item) }
                </div>
            );
        }

        return (
            <div
              block="Menu"
              elem="SubItemWrapper"
              key={ item_id }
              mods={ mods }
            >
                <MenuItem
                  activeMenuItemsStack={ activeMenuItemsStack }
                  item={ item }
                  closeMenu={ closeMenu }
                  isLink
                />
                { this.renderDesktopSubLevel(item) }
            </div>
        );
    };

    renderSubLevel(category) {
        const { activeMenuItemsStack } = this.props;
        const { item_id, children } = category;
        const childrenArray = getSortedItems(Object.values(children));
        const isVisible = activeMenuItemsStack.includes(item_id);
        const subcategoryMods = { type: 'subcategory' };

        return (
            <div
              block="Menu"
              elem="SubMenu"
              mods={ { isVisible } }
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="ItemList"
                  mods={ { ...subcategoryMods } }
                >
                    { this.renderShopColumn() }
                    <div className="blockColumn" />
                    { childrenArray.map(this.renderSubLevelItems) }
                    { this.renderImageColumn() }
                    { this.renderBottomBar() }
                </div>
            </div>
        );
    }

    renderFirstLevel = (item) => {
        const { item_id, item_class } = item;

        const isHideOnDesktop = item_class === 'Menu-ItemFigure_type_hideOnDesktop';

        return (
            <li
              block="Menu"
              elem="Item"
              key={ item_id }
              mods={ { isHideOnDesktop } }
            >
                { this.renderFirstLevelItems(item) }
            </li>
        );
    };

    renderTopLevel() {
        const { menu, activeMenuItemsStack, device } = this.props;
        const categoryArray = Object.values(menu);

        if (!categoryArray.length) {
            return null;
        }

        const [{ children, title: mainCategoriesTitle }] = categoryArray;
        const childrenArray = getSortedItems(Object.values(children));
        childrenArray.push({
            children: {},
            icon: null,
            is_active: true,
            item_class: 'Menu-Link-CatalogueLink',
            item_id: 999,
            parent_id: 278,
            position: 999,
            title: 'Spring Summer 2025 Catalogue',
            url: '/catalogue'
        });
        console.log(childrenArray);

        return (
            <>
                <div block="Menu" elem="MainCategories">
                    <ul
                      block="Menu"
                      elem="ItemList"
                      mods={ { type: 'main' } }
                      aria-label={ mainCategoriesTitle }
                    >
                        { childrenArray.map(this.renderFirstLevel) }
                    </ul>
                    { device.isMobile && this.renderShopColumn() }
                    { device.isMobile && this.renderMobileMenuContact() }
                    { !device.isMobile && activeMenuItemsStack.length <= 1 && this.renderAdditionalInformation() }
                    { this.renderAdditionalInformation(true) }
                </div>
                { this.renderSubMenuDesktop(children) }
            </>
        );
    }

    renderFirstLevelItems(item) {
        const {
            activeMenuItemsStack,
            handleSubcategoryClick,
            onCategoryHover,
            closeMenu,
            device
        } = this.props;

        const { children } = item;
        const childrenArray = Object.values(children);
        const itemMods = { type: 'main' };

        if (childrenArray.length && device.isMobile) {
            return (
                <div
                    // TODO: split into smaller components
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleSubcategoryClick(e, item) }
                  tabIndex="0"
                  block="Menu"
                  elem="SubCatLink"
                  role="button"
                >
                    <MenuItem
                      activeMenuItemsStack={ activeMenuItemsStack }
                      item={ item }
                      itemMods={ itemMods }
                      onCategoryHover={ onCategoryHover }
                      closeMenu={ closeMenu }
                      isLink
                    />
                </div>
            );
        }

        return (
            <MenuItem
              activeMenuItemsStack={ activeMenuItemsStack }
              item={ item }
              itemMods={ itemMods }
              onCategoryHover={ onCategoryHover }
              closeMenu={ closeMenu }
              isLink
            />
        );
    }

    renderSubMenuDesktop(itemList) {
        const { device } = this.props;
        if (device.isMobile) {
            return null;
        }

        const childrenArray = getSortedItems(Object.values(itemList));

        return childrenArray.map(this.renderSubMenuDesktopItems);
    }

    renderSubMenuDesktopItems = (item) => {
        const { item_id, children } = item;

        if (!Object.keys(children).length) {
            return null;
        }

        const { activeMenuItemsStack, closeMenu } = this.props;
        const isVisible = activeMenuItemsStack.includes(item_id);

        if (!isVisible) {
            return null;
        }

        return (
            <div
              block="Menu"
              elem="SubCategoriesWrapper"
              mods={ { isVisible } }
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="SubCategoriesWrapperInner"
                  mods={ { isVisible } }
                >
                    { this.renderAdditionalInformation() }
                    <div
                      block="Menu"
                      elem="SubCategories"
                    >
                        { this.renderSubLevel(item) }
                    </div>
                </div>
                <div
                  block="Menu"
                  elem="Overlay"
                  mods={ { isVisible } }
                  onMouseEnter={ closeMenu }
                />
            </div>
        );
    };

    renderAdditionalInformation(checkMobile = false) {
        const { device } = this.props;

        if (checkMobile && !device.isMobile) {
            return null;
        }

        return (
            <>
                { this.renderPromotionCms() }
            </>
        );
    }

    renderPromotionCms() {
        const { promoBanner } = this.props;

        if (!promoBanner) {
            return <div block="Menu" elem="Promotion" className="empty-menu" />;
        }

        return (
            <PromoBanner
              { ...this.props }
              { ...this.state }
            />
        );
    }

    render() {
        const { closeMenu } = this.props;

        return (
            <div
              block="Menu"
              elem="MenuWrapper"
              onMouseLeave={ closeMenu }
            >
                { this.renderTopLevel() }
            </div>
        );
    }
}

export default MenuComponent;
