/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable fp/no-let */
import {
    UilArrowLeft, UilBars, UilShoppingCart, UilUser
} from '@iconscout/react-unicons';
import {
    CART,
    CART_EDITING,
    CART_OVERLAY,
    CATEGORY,
    CHECKOUT,
    CHECKOUT_ACCOUNT,
    CHECKOUT_SUCCESS,
    CMS_PAGE,
    CONTACT_US,
    CUSTOMER_ACCOUNT,
    CUSTOMER_ACCOUNT_PAGE,
    CUSTOMER_SUB_ACCOUNT,
    CUSTOMER_WISHLIST,
    FILTER,
    MENU,
    MENU_SUBCATEGORY,
    PDP,
    POPUP,
    PRODUCT_COMPARE,
    SEARCH
} from '@scandipwa/scandipwa/src/component/Header/Header.config';
import { DEFAULT_STATE_NAME } from '@scandipwa/scandipwa/src/component/NavigationAbstract/NavigationAbstract.config';

import ClickOutside from 'Component/ClickOutside';
import DigitalCatalogueBanner from 'Component/DigitalCatalogueBanner';
import Link from 'Component/Link';
import Logo from 'Component/Logo';
import OfflineNotice from 'Component/OfflineNotice';
import PromoBanner from 'Component/PromoBanner';
import SearchField from 'Component/SearchField';
import { Header as SourceHeader } from 'SourceComponent/Header/Header.component';
import { isSignedIn } from 'Util/Auth';
import CSS from 'Util/CSS';
import browserHistory from 'Util/History';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace LjrPwa/Component/Header/Component/HeaderComponent */
export class HeaderComponent extends SourceHeader {
    renderMap = {
        cancel: this.renderCancelButton.bind(this),
        back: this.renderBackButton.bind(this),
        close: this.renderCloseButton.bind(this),
        share: this.renderShareWishListButton.bind(this),
        title: this.renderTitle.bind(this),
        logo: this.renderLogo.bind(this),
        account: this.renderAccount.bind(this),
        minicart: this.renderMinicart.bind(this),
        search: this.renderSearchField.bind(this),
        clear: this.renderClearButton.bind(this),
        edit: this.renderEditButton.bind(this),
        ok: this.renderOkButton.bind(this),
        miniMenu: this.renderMiniMenu.bind(this)
    };

    stateMap = {
        [DEFAULT_STATE_NAME]: {
            title: true,
            logo: true
        },
        [POPUP]: {
            title: true,
            close: true
        },
        [PDP]: {
            back: false,
            title: true
        },
        [CATEGORY]: {
            back: false,
            title: true
        },
        [CUSTOMER_ACCOUNT]: {
            title: true
        },
        [CUSTOMER_SUB_ACCOUNT]: {
            title: true,
            back: false
        },
        [CUSTOMER_ACCOUNT_PAGE]: {
            title: true
        },
        [CUSTOMER_WISHLIST]: {
            share: true,
            title: true,
            edit: true,
            ok: true
        },
        [MENU]: {
            search: true
        },
        [MENU_SUBCATEGORY]: {
            back: false,
            title: true
        },
        [SEARCH]: {
            back: false,
            search: true
        },
        [CART]: {
            title: true
        },
        [CART_OVERLAY]: {
            title: true
        },
        [CART_EDITING]: {
            ok: true,
            title: true,
            cancel: true
        },
        [FILTER]: {
            close: true,
            clear: true,
            title: true
        },
        [CHECKOUT]: {
            back: false,
            title: true,
            account: true
        },
        [CHECKOUT_SUCCESS]: {
            title: true,
            account: true
        },
        [CHECKOUT_ACCOUNT]: {
            title: true,
            close: true
        },
        [CMS_PAGE]: {
            back: false,
            title: true
        },
        [CONTACT_US]: {
            title: true,
            back: false
        },
        [PRODUCT_COMPARE]: {
            title: true,
            back: false
        }
    };

    renderMiniMenu() {
        const { device } = this.props;

        if (device.isMobile) {
            return (
                <button block="Header" elem="MiniMenuButton" aria-label="View Menu" onClick={ this.onMiniMenuClick }>
                    <UilBars />
                </button>
            );
        }

        return null;
    }

    renderBackButton(isVisible = false) {
        const { onBackButtonClick } = this.props;

        return (
            <button
              key="back"
              block="Header"
              elem="Button"
              mods={ { type: 'back', isVisible } }
              onClick={ onBackButtonClick }
              aria-label="Go back"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
            >
                <UilArrowLeft />
            </button>
        );
    }

    onMiniMenuClick() {
        if (browserHistory.location.pathname === '/menu') {
            browserHistory.goBack();
        } else {
            browserHistory.push(appendWithStoreCode('/menu'));
        }
    }

    renderTopMenu() {
        const { device } = this.props;
        if (device.isMobile) {
            return null;
        }

        return (
            <div block="Header" elem="TopMenu" />
        );
    }

    renderLogo(isVisible = false) {
        const { isLoading } = this.props;

        if (isLoading) {
            return null;
        }

        return (
            <Link
              to="/"
              aria-label="Go to homepage by clicking on L J & R Footwear logo"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
              block="Header"
              elem="LogoWrapper"
              mods={ { isVisible } }
              key="logo"
            >
                { this.renderLogoImage() }
            </Link>
        );
    }

    renderLogoImage() {
        const {
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width
        } = this.props;

        const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;

        CSS.setVariable(this.logoRef, 'header-logo-height', logo_height.toString().concat('rem'));
        CSS.setVariable(this.logoRef, 'header-logo-width', logo_width.toString().concat('rem'));

        return (
            <Logo
              src={ logoSrc }
              alt={ logo_alt }
              title={ logo_alt }
            />
        );
    }

    renderAccount(isVisible) {
        const {
            onMyAccountOutsideClick,
            isCheckout
        } = this.props;

        if (isCheckout && isSignedIn()) {
            return null;
        }

        return (
            <ClickOutside
              onClick={ onMyAccountOutsideClick }
              key="account"
            >
                <div
                  aria-label="My account"
                  block="Header"
                  elem="MyAccount"
                >
                    { this.renderAccountButton(isVisible) }
                    { this.renderAccountOverlay() }
                </div>
            </ClickOutside>
        );
    }

    renderAccountButton(isVisible) {
        const {
            onMyAccountButtonClick,
            customer
        } = this.props;

        const name = customer.firstname;

        let accountTitle = 'Sign In/Register';

        if (name) {
            accountTitle = 'My Account ('.concat(name).concat(')');
        }

        return (
            <button
              block="Header"
              elem="MyAccountWrapper"
              tabIndex="0"
              onClick={ onMyAccountButtonClick }
              aria-label="Open my account"
              id="myAccount"
            >
                <UilUser
                  height={ 30 }
                  width={ 30 }
                  block="Header"
                  mods={ { isVisible, type: 'account' } }
                />
                <div
                  block="Header"
                  elem="MyAccountTitle"
                >
                    { accountTitle }
                </div>
            </button>
        );
    }

    renderMinicart(isVisible) {
        const {
            onMinicartOutsideClick
        } = this.props;

        return (
            <ClickOutside
              onClick={ onMinicartOutsideClick }
              key="minicart"
            >
                <div
                  block="Header"
                  elem="Button"
                  mods={ { isVisible, type: 'minicart' } }
                >
                    { this.renderMinicartButton() }
                    { this.renderMinicartOverlay() }
                </div>
            </ClickOutside>
        );
    }

    renderMinicartButton() {
        return (
            <Link
              block="Header"
              elem="MinicartButtonWrapper"
              tabIndex="0"
              to="/cart"
            >
                <span
                  block="Header"
                  elem="MinicartTitle"
                />
                <UilShoppingCart
                  width={ 30 }
                  height={ 30 }
                  aria-label="Minicart"
                  block="Header"
                />
                { this.renderMinicartItemsQty() }
            </Link>
        );
    }

    renderPromoBanner() {
        const { device, promoBanner, navigationState: { name } } = this.props;

        if (name === 'menu') {
            return null;
        }

        if (!promoBanner) {
            return null;
        }

        if (device.isMobile) {
            return (
                <PromoBanner
                  promoBanner={ promoBanner }
                />
            );
        }

        return null;
    }

    renderDigitalCatalogueBanner() {
        const { device, navigationState: { name } } = this.props;

        if (name === 'menu') {
            return null;
        }

        if (device.isMobile) {
            return (
              <DigitalCatalogueBanner />
            );
        }

        return null;
    }

    renderSearchField(isVisible = false) {
        const {
            searchCriteria,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            navigationState: { name },
            isCheckout,
            hideActiveOverlay
        } = this.props;

        if (isCheckout) {
            return null;
        }

        if (name === 'menu') {
            return (
                <SearchField
                  key="search"
                  searchCriteria={ searchCriteria }
                  onSearchOutsideClick={ onSearchOutsideClick }
                  onSearchBarFocus={ onSearchBarFocus }
                  onSearchBarChange={ onSearchBarChange }
                  onClearSearchButtonClick={ onClearSearchButtonClick }
                  isVisible={ isVisible }
                  isActive={ name === SEARCH }
                  hideActiveOverlay={ hideActiveOverlay }
                />
            );
        }

        return (
            <SearchField
              key="search"
              searchCriteria={ searchCriteria }
              onSearchOutsideClick={ onSearchOutsideClick }
              onSearchBarFocus={ onSearchBarFocus }
              onSearchBarChange={ onSearchBarChange }
              onClearSearchButtonClick={ onClearSearchButtonClick }
              isVisible={ false }
              isActive={ name === SEARCH }
              hideActiveOverlay={ hideActiveOverlay }
            />
        );
    }

    render() {
        const { stateMap } = this;
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            device
        } = this.props;

        if (!device.isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        return (
            <section block="Header" elem="Wrapper">
                <header
                  block="Header"
                  mods={ { name, isHiddenOnMobile, isCheckout } }
                  mix={ { block: 'FixedElement', elem: 'Top' } }
                  ref={ this.logoRef }
                >
                    <nav block="Header" elem="Nav">
                        { this.renderNavigationState() }
                    </nav>
                    { this.renderPromoBanner() }
                    { this.renderDigitalCatalogueBanner() }
                    { this.renderMenu() }
                </header>
                <OfflineNotice />
            </section>
        );
    }
}

export default HeaderComponent;
