import React, { Component } from 'react';

import './DigitalCatalogueBanner.style.scss';

/** @namespace LjrPwa/Component/DigitalCatalogueBanner/Component/DigitalCatalogueBannerComponent */
export class DigitalCatalogueBannerComponent extends Component {
    render() {
        return (
          <div className="digitalCatalogueBanner">
              <div className="digitalCatalogue">
                  <p>
                      <a href="/catalogue">Download our Spring Summer 2025 Catalogue</a>
                  </p>
              </div>
          </div>
        );
    }
}

export default DigitalCatalogueBannerComponent;
